/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_header = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
			
		/*
		Frontpage or not?!
		*/
		if(
			url[2] == '' ||
			url[2] == 'vraag' ||
			url[2] == 'donatie' ||
			url[2] == 'donatie-done' ||
			url[2] == 'nieuwsbrief'
		) {

			$('.js-header-under').stop().animate({
				top:-100
			},300,'easein');

		} else {

			$('.js-header-under').stop().animate({
				top:0
			},500,'easein');

		}
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none

}).apply(specific_header); 