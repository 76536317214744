/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);

		/*
		Catch and active URL
		*/
		var _url_active_main = './';
		
		$('.js-nav-item').removeClass('active');
		
		if(url[3] !== '') { 
			_url_active_main = url[2] + '/' + url[3]; 
		} else {
			if(url[2] !== '') { 
				_url_active_main = url[2];
			}
		}

		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}

		/*
		Trigger: classes
		*/
		specific_header.onready();

		/*
		Trigger: Language switcher
		*/
		specific_languageswitcher.onready();

		/*
		Open popups on firstload
		*/
		// if(url[2] == 'donatie') {
		// 	popup.open(1,1,'donatie');			
		// }
		// if(url[2] == 'donatie-done') {
		// 	popup.open(1,1,'donatie-done');			
		// }
		if(url[2] == 'nieuwsbrief') {
			popup.open(1,1,'nieuwsbrief');
		}

		/*
		Instagram: Init
		*/
		if(
			$('.js-instagram-feed').length == 1
		) {
			specific_instagramfeed.onready();
		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
