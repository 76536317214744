/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_donate = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {

		/*
		Onetime
		*/
		$(document).on('click','.js-donate-goto-onetime',function() {

			$('.js-popup-donate-page').hide();

			$('.js-popup-donate-page-onetime').show();

			/*
			Popup: Set position
			*/
			popup.setPosition();

		});

		/*
		Montlhy
		*/
		$(document).on('click','.js-donate-goto-monthly',function() {

			$('.js-popup-donate-page').hide();

			$('.js-popup-donate-page-monthly').show();

			/*
			Popup: Set position
			*/
			popup.setPosition();

		});

		/*
		Online
		*/
		$(document).on('click','.js-donate-goto-online',function() {

			$('.js-popup-donate-page').hide();

			$('.js-popup-donate-page-online').show();

			/*
			Popup: Set position
			*/
			popup.setPosition();

		});

		/*
		Bank
		*/
		$(document).on('click','.js-donate-goto-bank',function() {

			$('.js-popup-donate-page').hide();
			
			$('.js-popup-donate-page-bank').show();

			/*
			Popup: Set position
			*/
			popup.setPosition();

		});

		/*
		Onetime: Selector
		*/
		$(document).on('click','.js-selector',function() {

			$('.js-selector').removeClass('active');
			$(this).addClass('active');

			var _price = $(this).data('price');
			$('.js-donate-price').val(_price);

			$('.js-donate-custom').removeClass('active');
			$('.js-donate-custom').val('');

		});

		$(document).on('input','.js-donate-custom',function(evt) {

			$('.js-selector').removeClass('active');
			$('js-donate-custom').addClass('active');

			var _price = $(this).val();

			/*
			Only 0-9
			*/
		    evt = (evt) ? evt : window.event;
		    var charCode = (evt.which) ? evt.which : evt.keyCode;
		    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		        return false;
		    }
		
			$('.js-donate-price').val(_price);

		});

		/*
		Onetime: Submit
		*/
		$(document).on('click','.js-donate-submit',function() {

			var _price = $('.js-donate-price').val();

			/*
			Open new window
			*/
			window.open('https://bunq.me/migreat/' + _price + '/-');

			/*
			Set: Thanks page
			*/
			$('.js-popup-donate-page').hide();

			$('.js-popup-donate-page-thanks').show();

			/*
			Popup: Set position
			*/
			popup.setPosition();

		});

		/*
		Monthly: Selector
		*/
		$(document).on('click','.js-selector-monthly',function() {

			$('.js-selector-monthly').removeClass('active');
			$(this).addClass('active');

			var _price = $(this).data('price');
			$('.js-donate-price-monthly').val(_price);

			$('.js-donate-custom-monthly').removeClass('active');
			$('.js-donate-custom-monthly').val('');

		});

		$(document).on('input','.js-donate-custom-monthly',function(evt) {

			$('.js-selector-monthly').removeClass('active');
			$('js-donate-custom-monthly').addClass('active');

			var _price = $(this).val();

			/*
			Only 0-9
			*/
		    evt = (evt) ? evt : window.event;
		    var charCode = (evt.which) ? evt.which : evt.keyCode;
		    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		        return false;
		    }
		
			$('.js-donate-price-monthly').val(_price);

		});

		/*
		Submit
		*/
		$(document).on('click','.js-donate-submit-monthly2',function() {

			/*
			AJAX
			*/
			$.ajax({
				url	: globalUrl + '/' + _language + '/a/a/stripe-checkout',
				type: "POST",
				data: {
					language	: _language,
					amount		: $('.js-donate-price-monthly').val(),
				},
				headers: { 
					'go-load':1 
				}
			})
			.done(function(data) {

				/*
				Redirect to Stripe link
				*/
				window.location.href = data;

			})
			.always(function() {
			});

			return false;

		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none

}).apply(specific_donate); 