/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_donate = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function () {
		
		/*
		Timing
		Timing
		Timing
		Timing
		*/
		$(document).on('click', '.js-radiobutton-timing', function () {
			
			var _value = $(this).data('value');
			$('.js-donate-timing').val(_value);

			/*
			Remove: Selected
			*/
			$('.js-radiobutton-timing').removeClass('selected');

			/*
			Add: Selected
			*/
			$(this).addClass('selected');

		});

		/*
		Newsletter
		Newsletter
		Newsletter
		Newsletter
		*/
		$(document).on('click', '.js-checkbox-newsletter', function () {
			
			/*
			Selected or not?
			*/
			if (
				$('.js-donate-newsletter').val() == 'no'
			) {
				$('.js-donate-newsletter').val('yes');
				$('.js-checkbox-newsletter').addClass('selected');
			} else {
				$('.js-donate-newsletter').val('no');
				$('.js-checkbox-newsletter').removeClass('selected');
			}

		});

		/*
		Amount
		*/
		$(document).on('input', '.js-donate-amount', function (evt) {

			var _price = $(this).val();

			/*
			Allow only numbers
			*/
			_price = _price.replace(/[^0-9]/g, '');
		
			$('.js-donate-amount').val(_price);

		});
		
		/*
		Submit
		*/
		$(document).on('click', '.js-donate-submit', function () {
			
			/*
			Check: Values
			*/
			var _firstname 	= $('.js-donate-firstname').val();
			var _lastname 	= $('.js-donate-lastname').val();
			var _email 		= $('.js-donate-email').val();
			
			var _amount 	= $('.js-donate-amount').val();
			var _timing 	= $('.js-donate-timing').val();
			var _newsletter = $('.js-donate-newsletter').val();

			var _message 	= $('.js-donate-message').val();
			
			var _url = globalUrl + '/' + _language + '/a/a/save-donate';
			
			/*
			Validate
			Amount need to be filled in and at least 1
			*/
			if (
				_amount == '' ||
				_amount == 0
			) {
				if (_language == 'nl') {
					alert('Vul een bedrag in.');
				} else {
					alert('Please fill in an amount.');
				}
				return false;
			}

			/*
			Check newsletter
			*/
			if (
				_newsletter == 'yes'
			) {

				if (
					_email == ''
				) {
					if (_language == 'nl') {
						alert('Je bent vergeten jouw e-mailadres in te vullen.');
					} else {
						alert('You forgot to fill in your e-mail address.');
					}
					return false;
				}
				
			}

			/*
			Class: Loading add
			*/
			$('.js-donate-submit').addClass('loading');

			/*
			Timing
			Timing
			Timing
			Timing
			*/
			if (
				_timing == 'one-time'
			) {
				
				/*
				AJAX
				*/
				$.ajax({
					url	: _url,
					type: "POST",
					data: {
						language	: _language,
						firstname 	: _firstname,
						lastname 	: _lastname,
						email 		: _email,
						amount 		: _amount,
						timing 		: _timing,
						newsletter 	: _newsletter,
						message 	: _message
					},
					headers: { 
						'go-load':1 
					}
				})
				.done(function (data) {
					
					/*
					Thanks message
					*/
					$('.js-donate-form').html(data);

					/*
					Redirect to Mollie link
					*/
					window.location.href = 'https://bunq.me/migreat/' + _amount + '/-';
					// window.open('https://bunq.me/migreat/' + _amount + '/-');

				})
				.always(function() {
				});
				
			} else {
				
				/*
				AJAX
				*/
				$.ajax({
					url	: _url,
					type: "POST",
					data: {
						language	: _language,
						firstname 	: _firstname,
						lastname 	: _lastname,
						email 		: _email,
						amount 		: _amount,
						timing 		: _timing,
						newsletter 	: _newsletter,
						message 	: _message
					},
					headers: { 
						'go-load':1 
					}
				})
				.done(function(data) {
					
					/*
					Redirect to Stripe link
					*/
					window.location.href = data;

				})
				.always(function() {
				});
				
			}

			return false;

		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none

}).apply(specific_donate); 