/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	30-12-2024
	- [BORN] This class is born. Hooray!

!Usage
	specific_instagramfeed.onready();
	
!Wishlist

	none
	
*/
  
var specific_languageswitcher = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function() {
		
		url 		= getUrl();

		_language 	= url[1];
		_part_1 	= url[2];
		_part_2 	= url[3];

		_array_nl = [
			'nieuws',
			'de-beweging',
			'over-ons',
			'contact',
			'doe-mee',	
			'donatie',
			'nieuwsbrief'
		];

		_array_en = [
			'news',
			'the-movement',
			'about-us',
			'contact',
			'join',	
			'donatie',
			'nieuwsbrief'
		];

		/*
		Search _array_nl and _array_en for _part_1
		*/
		_index = _array_nl.indexOf(_part_1);

		if(
			_language == 'nl'
		) {
			/*
			Change to english
			*/

			_index = _array_nl.indexOf(_part_1);

			_url_new = globalUrl + '/en';
			
			if(
				_part_1 != ''
			) { 
				_url_new = _url_new + '/' + _array_en[_index];
			}

			if(
				_part_2 != ''
			) { 
				_url_new = _url_new + '/' + _part_2;
			}

		} else {
			/*
			Change to dutch
			*/

			_index = _array_en.indexOf(_part_1);

			_url_new = globalUrl + '/nl';
			
			if(
				_part_1 != ''
			) { 
				_url_new = _url_new + '/' + _array_nl[_index];
			}

			if(
				_part_2 != ''
			) { 
				_url_new = _url_new + '/' + _part_2;
			}

		}		

		/*
		Change the URL
		*/
		$('.js-languageswitcher').attr('href',_url_new);
		
	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	// none
		
}).apply(specific_languageswitcher);