/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	30-12-2024
	- [BORN] This class is born. Hooray!

!Usage
	specific_instagramfeed.onready();
	
!Wishlist

	none
	
*/
  
var specific_instagramfeed = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	this.onready = function() {

		/*
		XHR
		*/
		$.ajax({
			url: globalUrl + '/' + url[1] + '/a/l/instagram',
			type: 'POST',
			data: {
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			$('.js-instagram-feed').html(data);

		})
		.always(function() {
		});

	};
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	// none
		
}).apply(specific_instagramfeed);